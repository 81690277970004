<template>
  <div class="display_list">
    <div class="bannar">
      <van-image fit="contain" src="/static/outcome_bg.jpg"/>
    </div>
    <van-tabs class="tabs_box van-tabs__wrap--scrollable" v-model="active" swipeable title-active-color="#4B99FF" border @change="onTabChange">
      <van-tab  v-for="(item) in projList" :title="item.name" :key="item.id" >
        <van-cell v-for="(item,index) in outcomeList" :key="index" @click="forDetail(item)">
          <template #title>
            <van-row>
              <van-col span="16">
                <div class="title_text">{{item.title}}</div>
                <div class="date_text">{{item.updated_at}}</div>
              </van-col>
              <van-col span="8">
                <van-image fit="contain" :src="baseUrl + item.kv"/>
              </van-col>
            </van-row>
          </template>
        </van-cell>
        <div class="select_icon" @click="selectProject()"><van-icon name="wap-nav" size="18" color="#646566"/></div>
      </van-tab>
      <!-- <template #nav-left> 
        <div class="select_icon" @click="selectProject()"><van-icon name="wap-nav" size="18" color="#646566"/></div>
      </template> -->
      
    </van-tabs>
    
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm"/>
    </van-popup>
  </div>
</template>

<script>
import { 
  getOutcome,
  getShowProject
} from '@/api/api'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      active: 0, 
      showPicker: false,
      projList: [],
      columns: [],
      outcomeList: [],
      project_id: null,
    }
  },
  computed: {
    ...mapState['userId']
  },
  created() {
    this.getData()
    console.log("created")
  },
  mounted() {
  },
  methods: {
    async getData() {
      console.log("this.userId",this.userId)
      await getShowProject().then((res) => {
        console.log("获取项目列表",res)
        for(let i in res) {
          let item = {}
          item.id = i
          item.name = res[i]
          console.log(i)
          console.log(res[i])
          this.projList.push(item)
          this.columns.push(res[i])
        }
        this.projList.reverse()
        this.columns.reverse()
        this.project_id = this.projList[0].id
        this.getOutcomeList(this.project_id)  //初始化列表默认展示首个项目
        this.ifShowSelectIcon = true
      })
    },
    onTabChange(name,title) {
      console.log("name",name)
      console.log("title",title)
      let project_id = null
      for(let i in this.projList){
        if(this.projList[i].name == title){
          console.log("this.projList[i]",this.projList[i])
          project_id = this.projList[i].id
        }
      }
      console.log("project_id",project_id)
      this.project_id = project_id
      this.outcomeList = []
      this.getOutcomeList(this.project_id)
    },
    async getOutcomeList(project_id) {
      console.log("project_idxxxx：",project_id)
      await getOutcome({
        params:{
          project_id: project_id,
        }
      }).then(res => {
        console.log("成果展示res:",res)
        this.outcomeList = res.items
      })
    },
    forDetail(item) {
      console.log("跳转至列表详情页item",item)
      console.log("outcome_id",item.id)
      console.log("project_id",this.project_id)
      this.$router.push({
        name:"displayDetail",
        query: {
          outcome_id: item.id,
          projectId: this.project_id
        }
      })

    },
    onConfirm(proj_name) {
      console.log("project_name:",proj_name)
      let active_index = this.columns.indexOf(proj_name)
      this.active = active_index
      let project_id = null
      for(let i in this.projList){
        if(this.projList[i].name == proj_name){
          console.log("this.projList[i]",this.projList[i])
          project_id = this.projList[i].id
        }
      }
      console.log("project_id",project_id)
      this.project_id = project_id
      this.outcomeList = []
      this.getOutcomeList(this.project_id)
      this.showPicker = false
    },
    selectProject() {
      // 选择项目
      this.showPicker = true
      console.log("选择项目")
    },
  }
}
</script>

<style lang="scss" scoped>
.display_list{
  .bannar{
    display: flex;
  }
  .tabs_box{
    position: relative;
    .select_icon{
      position: absolute;
      height: 44px;
      top: 0;
      left: 0;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      // padding-bottom: 5px;
      // padding-left: 5px;
    }
  }
  ::v-deep .van-tabs__line{
    background-color: #fff;
  }
  .title_text{
    font-size: 16px;
    text-align: left;
    line-height: 28px;
    color: #0A0A0A;
    // background: #626262;
    padding-right: 5px;
  }
  .date_text{
    font-size: 13px;
    text-align: left;
    color: #626262;
    line-height: 13px;
    margin-top: 16px;
    // line-height: 28px;
  }
}
</style>